<template>
  <div class="pucontainer">
    <div class="navtitle">
      <h5 class="blod">
        {{
          params.htype == 1
          ? $t("common.men")
          : params.htype == 2
            ? $t("common.women")
            : params.htype == 3 || params.htype == 4
              ? $t("common.kids")
              : $t("common.all")
        }}
        <span v-if="$route.query.discount == 1">&nbsp;{{ $t("common.sale") }}</span>
        {{ $i18n.locale == "en" ? categoryname.titleen : categoryname.title }}
      </h5>
      <div>
        <span @click="goHome">
          {{
            params.htype == 1
            ? $t("common.men")
            : params.htype == 2
              ? $t("common.women")
              : params.htype == 3 || params.htype == 4
                ? $t("common.kids")
                : $t("common.all")
          }}
        </span>
        <span v-if="$route.query.discount == 1">&nbsp;/ {{ $t("common.sale") }}</span>
        <span @click="resetHandle()">
          &nbsp;/
          {{
            params.htype == 3
            ? $t("common.boy")
            : params.htype == 4
              ? $t("common.girl")
              : $t("common.collections")
          }}
        </span>
        <span v-if="brandName">&nbsp;/ {{ brandName }}</span>
        <span v-if="categoryname.title">&nbsp;/
          {{
            $i18n.locale == "en" ? categoryname.titleen : categoryname.title
          }}</span>
        <span v-if="params.currencycommodityname">&nbsp;/ {{ params.currencycommodityname }}</span>
      </div>
    </div>
    <div class="cedBrand">
      <div class="box" v-for="(item, index) in cateSelectArr" :key="item.id">
        <q-btn unelevated color="primary" icon-right="close" :label="$i18n.locale == 'en' ? item.titleen : item.title"
          @click="removeSelect('分类', item, index)" />
      </div>
      <div class="box" v-for="(item, index) in brandSelectArr" :key="item.id">
        <q-btn unelevated color="primary" icon-right="close" :label="item.title"
          @click="removeSelect('品牌', item, index)" />
      </div>
      <div class="box" v-if="cateSelectArr.length || brandSelectArr.length">
        <q-btn color="primary" outline :label="$t('goods_list.all_remove')" @click="removeAllSelect" />
      </div>
    </div>
    <div class="select-mode">
      <q-select dense v-model="mode.current" :options="mode.list" :label="$t('goods_list.mode')"
        @update:model-value="modeChange" behavior="menu" style="width: 200px" />
    </div>
    <div class="goodsCon">
      <goods-left></goods-left>
      <goods-right></goods-right>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { reactive, computed, onMounted, watch } from 'vue'
import GoodsLeft from './GoodsLeft.vue'
import GoodsRight from './GoodsRight.vue'
import i18n from '@/language'
import event from '../../../utils/event'
import { useRoute, useRouter } from 'vue-router'
import _ from 'lodash'

export default {
  components: {
    GoodsLeft,
    GoodsRight
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const $t = i18n.global.t
    const params = computed(() => store.state.goodsModule.params)
    const mode = reactive({
      list: [
        { label: $t('goods_list.mode_all'), value: 0 },
        { label: $t('goods_list.new'), value: 5 },
        { label: $t('goods_list.low_price'), value: 4 },
        { label: $t('goods_list.high_price'), value: 2 }
      ],
      current: { label: $t('goods_list.mode_all'), value: 0 }
    })
    const cateSelectArr = computed(() => store.state.goodsModule.cateSelectArr)
    const brandSelectArr = computed(
      () => store.state.goodsModule.brandSelectArr
    )
    // 查看方式变化
    const modeChange = e => {
      store.commit('modeChange', e)
    }
    // 全部移除
    const removeAllSelect = () => {
      event.emit('removeAll')
      store.commit('removeAllSelect')
    }
    // 移除顶部筛选块
    const removeSelect = (type, item, index) => {
      item.isSelect = false
      if (type === '分类') {
        store.commit('removeCateSelect', index)
      } else if (type === '品牌') {
        store.commit('removeBrandSelect', index)
      }
    }
    // 全部清空重置
    const resetHandle = () => {
      mode.current = mode.list[0]
      event.emit('removeAll')
      event.emit('resetAllCate')
      store.commit('resetHandle', params.value.htype)
    }
    // 查找排序方式
    const findOrder = (param) => {
      const cur = mode.list.find((o) => o.value === param.order)
      mode.current = cur
    }
    onMounted(() => {
      try {
        const param = JSON.parse(decodeURI(route.query.params))
        if (route.query.discount == 1) {
          param.isdiscount = 1
        }
        findOrder(param)
        store.commit('restoreHandle', param)
      } catch (error) {

      }
    })
    const isUpdataParams = computed(() => store.state.goodsModule.isUpdataParams)
    watch(isUpdataParams, (newVal) => {
      if (newVal) {
        findOrder(params)
      }
    })
    const categoryname = computed(() => store.state.goodsModule.categoryname)
    const brandName = computed(() => store.state.goodsModule.brandName)
    watch(params, () => {
      if (params.value.htype && params.value.htype !== store.state.pageType.id) {
        const obj = {
          id: params.value.htype,
          title:
            params.value.htype === '1'
              ? '男士'
              : params.value.htype === '2'
                ? '女士'
                : '儿童'
        }
        store.commit('setPageType', obj)
      }
      setTimeout(() => {
        store.dispatch('getList')
      }, 0)
    }, {
      deep: true
    })
    const queryParams = computed(() => store.state.goodsModule.queryInfo)
    watch(queryParams, (val) => {
      const query = JSON.stringify(val)
      router.replace({
        path: '/goodslist',
        query: {
          params: encodeURI(query),
          discount: route.query.discount
        }
      })
    }, {
      deep: true
    })
    return {
      params,
      mode,
      modeChange,
      removeAllSelect,
      removeSelect,
      resetHandle,
      cateSelectArr,
      brandSelectArr,
      categoryname,
      brandName
    }
  }
}
</script>

<style lang="scss" scoped>
.pucontainer {
  .navtitle {
    text-align: center;
    margin-bottom: 34px;
    text-transform: uppercase;

    h5 {
      font-size: 20px;
      margin-bottom: 5px;
      font-weight: bold;
    }

    span {
      cursor: pointer;
    }
  }

  .cedBrand {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .box {
      margin: 0 5px 10px 5px;
    }
  }

  .select-mode {
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
  }

  .goodsCon {
    max-width: 1500px;
    margin: 0 auto;
    padding: 10px;
    display: flex;
  }
}
</style>
