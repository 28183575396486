<template>
  <div class="navleft">
    <q-list separator>
      <q-expansion-item :label="$t('goods_list.cate_all')" default-opened v-show="crowdSelectShow">
        <q-card-section>
          <div class="cate-list">
            <p @click="crowdChange()">
              {{ $t("goods_list.all") }}
            </p>
            <p v-for="(item, index) in pageList" :key="index" @click="crowdChange(item.id)">
              {{ item.title }}
            </p>
          </div>
        </q-card-section>
      </q-expansion-item>
      <q-expansion-item :label="$t('common.sale')" default-opened>
        <div>
          <q-card-section>
            <q-checkbox size="xs" dense v-model="isSaleStatus" :label="$t('common.sale')"
              @update:model-value="isSaleSwitch()" />
          </q-card-section>
        </div>
      </q-expansion-item>
      <q-expansion-item :label="$t('goods_list.cate_sub')" default-opened v-show="params.htype">
        <q-card-section>
          <div class="cate-list blod" @click="AllCate()" style="cursor: pointer">
            {{ $t("goods_list.look_all") }}
          </div>
          <div class="cate-list" v-for="(item) in cate.list" :key="item.id">
            <p @click="setCateSub(item)">
              {{ $i18n.locale == "en" ? item.titleen : item.title }}
            </p>
            <div v-show="item.show" v-for="(cate) in cate.subList" :key="cate.id">
              <q-checkbox size="xs" dense v-model="cate.isSelect"
                :label="$i18n.locale == 'en' ? cate.titleen : cate.title" @update:model-value="getCateSub(cate)" />
            </div>
          </div>
        </q-card-section>
      </q-expansion-item>
      <q-expansion-item :label="$t('goods_list.brands')" v-show="!brand.isOnlyBrand && params.htype" default-opened>
        <q-card-section>
          <q-input dense :label="$t('goods_list.search')" @update:model-value="searchBrandChange">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
          <q-scroll-area style="height: 200px">
            <div v-for="(item) in brand.list" :key="item.id" style="margin-top: 10px" v-show="item.show">
              <q-checkbox size="xs" dense v-model="item.isSelect" :label="item.title"
                @update:model-value="getBrand(item)" />
            </div>
          </q-scroll-area>
        </q-card-section>
      </q-expansion-item>
      <q-expansion-item v-show="list.length" :label="$t('goods_list.price')" default-opened>
        <q-card-section>
          <div class="price-section">
            <div class="input">
              <q-input :label="'￥' + section.range.min" dense mask="#" reverse-fill-mask v-model="section.low"
                @blur="rangeBlur" />
            </div>
            <div class="input">
              <q-input :label="'￥' + section.range.max" dense mask="#" reverse-fill-mask v-model="section.top"
                @blur="rangeBlur" />
            </div>
          </div>
          <q-range v-model="section.range" :min="section.lowerPrice" :max="section.topPrice" @change="rangeChange" />
        </q-card-section>
      </q-expansion-item>
    </q-list>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { reactive, computed, onMounted, onBeforeUnmount, ref, toRaw, watch, watchEffect } from 'vue'
import { currency2 } from '@/api/common'
import i18n from '@/language'
import { useRoute } from 'vue-router'
import _ from 'lodash'
import { useQuasar } from 'quasar'
import event from '../../../utils/event'

export default {
  setup() {
    const $q = useQuasar()
    const route = useRoute()
    const store = useStore()
    const $t = i18n.global.t
    const pageList = reactive([
      { id: '1', title: $t('common.men') },
      { id: '2', title: $t('common.women') },
      { id: '3', title: $t('common.boy') },
      { id: '4', title: $t('common.girl') }
    ])
    const request = ref(0)
    const params = computed(() => store.state.goodsModule.params)
    const list = computed(() => store.state.goodsModule.list)
    const crowdSelectShow = ref(false)
    const isSaleStatus = ref(false)
    const isSale = computed(() => store.state.goodsModule.params.isdiscount)
    watch(isSale, (val) => {
      isSaleStatus.value = !!val
    })
    const brand = reactive({
      list: [], // 品牌列表
      isOnlyBrand: computed(() => store.state.goodsModule.isOnlyBrand) // 是否是单独品牌
    })
    const getBrandList = async () => {
      if (params.value.htype === '') {
        return
      }
      request.value++
      const res = await currency2({
        id: params.value.htype
      })
      if (res.status === 1) {
        brand.list = _.get(res, 'item[0].generalattribute[0].item') || []
        brand.list.forEach(item => {
          item.isSelect = false
          item.show = true
        })
      }
      request.value--
    }
    // 品牌搜索框搜索品牌
    const searchBrandChange = e => {
      brand.list.forEach(item => {
        if (item.title.toLowerCase().indexOf(e.toLowerCase()) >= 0) {
          item.show = true
        } else {
          item.show = false
        }
      })
    }

    const cate = reactive({
      list: [], // 分类列表
      subList: [] // 子分类列表
    })
    const getCate = () => {
      if (params.value.htype === '') {
        return
      }
      const navItem = JSON.parse(localStorage.getItem('navItem') || [])
      const item = navItem.find(val => {
        return val.id === params.value.htype
      })
      // 获取分类
      item.categorys.forEach(i => {
        i.show = false
        i.item.forEach(j => {
          j.isSelect = false
        })
      })
      cate.list = item.categorys
    }

    // 查看全部分类
    const AllCate = () => {
      cate.list.forEach(o => {
        o.show = false
      })
      cate.subList.forEach(o => {
        o.isSelect = false
      })
      store.commit('AllCate')
    }
    // 人群变化
    const crowdChange = (id) => {
      store.commit('crowdChange', id)
    }
    // 设置子分类
    const setCateSub = item => {
      cate.list.forEach(i => {
        i.show = false
        i.item.forEach(j => {
          j.isSelect = false
        })
      })
      cate.subList = item.item
      item.show = true
      store.commit('setCateSub', item)
    }
    // 获取子分类选中
    const getCateSub = (item) => {
      store.commit('getCateSub', cate.subList)
    }
    // 获取品牌选中
    const getBrand = () => {
      store.commit('getBrand', brand.list)
    }
    // 取消全部选中
    const removeAllSelect = () => {
      cate.subList.forEach(o => {
        o.isSelect = false
      })
      if (brand.isOnlyBrand) {
        return
      }
      brand.list.forEach(o => {
        o.isSelect = false
      })
    }
    const low = computed(() => store.state.goodsModule.section.lowerPrice)
    const top = computed(() => store.state.goodsModule.section.topPrice)
    // 价格区间
    const section = reactive({
      low: '', // 输入框
      top: '', // 输入框
      range: {
        min: 1,
        max: 1
      },
      lowerPrice: low,
      topPrice: top
    })
    watch(low, (val) => {
      section.range.min = val
    }, {
      immediate: true
    })
    watch(top, (val) => {
      section.range.max = val
    }, {
      immediate: true
    })
    // 价格范围变化
    const rangeChange = (e) => {
      store.commit('rangeChange', e)
    }
    // 价格区间blur
    const rangeBlur = () => {
      if (section.low === '') {
        section.low = section.range.min
      }
      if (section.top === '') {
        section.top = section.range.max
      }
      section.low = parseInt(section.low)
      section.top = parseInt(section.top)
      // 最低价高于最高价时位置调换
      if (section.low > section.top) {
        const num = section.top
        section.top = section.low
        section.low = num
      }
      // 防止价格超出最低价
      if (section.low < section.lowerPrice) {
        $q.notify($t('goods_list.tip_low'))
        section.low = toRaw(section.lowerPrice)
      }
      // 防止价格超出最高价
      if (section.top > section.topPrice) {
        $q.notify($t('goods_list.tip_top'))
        section.top = toRaw(section.topPrice)
      }
      section.range.min = section.low
      section.range.max = section.top
      const obj = {
        low: section.low,
        top: section.top
      }
      store.commit('rangeBlur', obj)
      section.low = ''
      section.top = ''
    }
    // 监听人群变化
    watch(() => params.value.htype, () => {
      getCate()
      getBrandList()
    })
    // 查找大分类
    const findCate = (parentcategoryid, categoryid) => {
      let cur = ''
      cate.list.forEach(o => {
        o.show = false
        if (o.guid === parentcategoryid) {
          cur = o
        }
      })
      if (cur) {
        const obj = {
          title: cur.title,
          titleen: cur.titleen
        }
        store.commit('setCategoryname', obj)
        cate.subList = cur.item
        cur.show = true
        cate.subList.forEach(o => {
          if (categoryid.indexOf(o.guid) !== -1) {
            o.isSelect = true
          } else {
            o.isSelect = false
          }
        })
        store.commit('restoreCateSelect', cate.subList)
      }
    }
    // 查找品牌
    const findBrand = (generalattributeid) => {
      if (brand.isOnlyBrand) {
        const cur = brand.list.find((o) => {
          return generalattributeid.indexOf(o.guid) !== -1
        })
        if (cur) {
          store.commit('setBrandName', cur.title)
        }
        return
      }
      brand.list.forEach(o => {
        if (generalattributeid.indexOf(o.guid) !== -1) {
          o.isSelect = true
        } else {
          o.isSelect = false
        }
      })
      store.commit('restoreBrandSelect', brand.list)
    }
    // 还原价格区间
    const findPrice = (low, top) => {
      if (low === '' || top === '') return
      setTimeout(() => {
        section.range.min = low
        section.range.max = top
      }, 1500)
    }
    // 根据路由还原选项操作
    const restore = (data) => {
      findCate(data.parentcategoryid, data.categoryid)
      const stop = watchEffect(() => {
        if (request.value === 0) {
          if (data.generalattributeid) {
            findBrand(data.generalattributeid)
          }
          setTimeout(() => {
            stop()
          }, 0)
        }
      })
      findPrice(data.Price_f, data.Price_a)
    }
    const isUpdataParams = computed(() => store.state.goodsModule.isUpdataParams)
    watch(isUpdataParams, (newVal) => {
      if (newVal) {
        findCate(params.value.parentcategoryid, params.value.categoryid)
        findBrand(params.value.generalattributeid)
      }
    })
    onMounted(() => {
      if (route.query.discount || !params.value.htype) {
        crowdSelectShow.value = true
      } else {
        getCate()
        getBrandList()
      }
      try {
        const temp = JSON.parse(decodeURI(route.query.params))
        setTimeout(() => {
          restore(temp)
        }, 0)
      } catch (error) {

      }
      event.on('removeAll', removeAllSelect)
      event.on('resetAllCate', AllCate)
    })
    onBeforeUnmount(() => {
      event.off('removeAll', removeAllSelect)
      event.off('resetAllCate', AllCate)
    })

    const isSaleSwitch = () => {
      if (isSaleStatus.value) {
        store.commit('setDiscount', 1)
      } else {
        store.commit('setDiscount', 0)
      }
    }
    return {
      list,
      params,
      pageList,
      brand,
      cate,
      crowdSelectShow,
      section,
      isSaleStatus,
      searchBrandChange,
      AllCate,
      crowdChange,
      setCateSub,
      getCateSub,
      getBrand,
      rangeChange,
      rangeBlur,
      isSaleSwitch
    }
  }
}
</script>

<style lang="scss" scoped>
.navleft {
  width: 240px;
  flex-shrink: 0;
  font-size: 12px;

  &>div {
    width: 100%;
  }

  .small {
    color: #666;

    &:hover {
      color: #000;
    }
  }

  .price-section {
    display: flex;
    justify-content: space-between;

    .input {
      width: 80px;
    }
  }

  .cate-list {
    margin: 3px 0;
    text-transform: uppercase;

    p {
      cursor: pointer;
      margin-bottom: 0;

      &:hover {
        font-weight: bold;
      }
    }
  }
}
</style>
